<template>    

    <v-skeleton-loader type="opti-input" v-if="skeleton"></v-skeleton-loader>

    <v-autocomplete v-else                                
        v-model="dvalue"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        hide-details
        :disabled="disabled"
        outlined 
        dense
        :clearable="compClearable"
        :placeholder="placeholder"
        :rules="rules"
        :value-comparator="myValueComparator"
        :filter="customFilter"
        class="focus-clearable focus-editable"
        :class="compClass"
    >

        <template v-slot:prepend>
            <ActionButton v-if="!noEdit && actionOpen && canOpen" icon-button icon="edit" :action="actionOpen" :disabled="disabled" :payload="{id: value}"/>
        </template>

        <template v-slot:item="{ index, item }">        
            <slot name="item" :item="item" :index="index">
                {{item[itemText]}}
            </slot>
        </template>
        
        <template v-slot:selection="data">
            <slot name="selection" :data="data" :sel="data.item">
                {{ data.item[itemText] }}
            </slot>
        </template>
        <template v-slot:bselection="data">
            UUUUU
        </template>

        <template v-slot:prepend-item class="mt-0"> 
            <v-list-item v-if="!noEdit && actionCreate && canCreate">
                <v-list-item-content >
                    <ActionButton sub :action="actionCreate">Nieuw</ActionButton>    
                </v-list-item-content>
            </v-list-item>

            <slot name="prepend-item"></slot>            
        </template>

        <template v-slot:no-data class="mt-0"> 
            <slot name="no-data"></slot>            
        </template>

    </v-autocomplete>

</template>

<script>

// Generic Action combobox, intended as base component for other combo's.
//  
// import ActionCombo from '@controls/combo/ActionCombo'
// 
// Usage: 
//  model.names = [{id: 1, name: "Herman"}, {id: 2, name: "Sjoerd"}]
//  <Combo :disabled="model.disabled" :rules="model.rules.name" v-model="model.name" :items="model.names"></Combo>
//
import string from '@lib/string'
import component from '@lib/ui/component'
import ActionButton from '@controls/buttons/ActionButton'
import navigation from '@app/navigation'

export default component.scaffold({
    name: 'ItemsCombo',
    components: {ActionButton},
    props: {
        items: {
            type: [Array],
            default: ()=>[]
        },
        placeholder: {
            type: [String],
            default:""
        },
        itemText: {
            type: [String, Number],
            default: "name"
        },
        itemValue: {
            type: [String, Number],
            default: "id"
        },
        value: {
            type: [String, Number]
        },
        disabled: {
            type: [Boolean]
        },
        rules: {
            type: [Array]
        },
        clearable: {
            type: [Boolean],
            default: true
        },
        noClearable: {
            type: [Boolean],
            default: false
        },
        actionOpen: null,
        actionCreate: null,
        valueComparator: null,
        customFilter: null,
        skeleton: {
            type: [Boolean]
        },
        noEdit: {
            type: [Boolean],
            default: false
        },
        noBorder: {
            type: [Boolean]
        },
        borderHover: {
            type: [Boolean]
        },

    },
    data () {
        return {                            
            canCreate: true,
            canOpen: true 
        }
    },
    methods: {
        myValueComparator: function(a,b) {

            if (this.valueComperator) {
                return this.valueComperator(a,b); 
            }
            return string.trim(a) == string.trim(b);
        },
        clear: function() {
            this.dvalue = null;
        },
    },
    computed: {
        dvalue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }	
        },
        compClearable: function() {
            if (this.noClearable) {
                return false;
            }
            return this.clearable;
        },
        compClass: function() {
            var border = this.noBorder ? "no-border" : ""; 
            var borderHover = this.borderHover ? "border-hover" : ""; 
            return `${border} ${borderHover}`;
        },

    },
    registerEvents(self) {
        if (!self.eventSaved) {
            return [];
        }
        var onSaved = function(data) {
            if (!data || !data.id) {
                return;
            }
            self.dvalue = -1;
            setTimeout( function() {
                self.dvalue = data.id;
            }, 250);
        };
        return [
            self.eventSaved.on(onSaved)
        ]
    },

    mounted(self) {
        if (self.noEdit) {
            self.canCreate = false;
            self.canOpen = false;
            return;
        } 

        if (!self.actionCreate) {
            self.canCreate = false;
        } else {
            self.canCreate = navigation.isAuthorized(self.actionCreate);
        }
        if (!self.actionOpen) {
            self.canOpen = false;
        } else {
            self.canOpen = navigation.isAuthorized(self.actionOpen);
        }

    }
})

</script>

<template>    
    <v-form @submit.prevent="'true'" ref="form">
        <div v-if="user.administrations.length < 5">

            
           <Button :loading="user.isLoading&&user.id_administration==admini.id" :disabled="user.isLoading" block class="administration mb-2 text-left py-8" v-for="(admini, i) in user.administrations" :key="i" @click="onSelect(admini.id)">
                <span style="width:100%;text-align:left;" class="d-inline-block"><Icon small class="mr-4" type="company" color="primary"></Icon>{{admini.name}}</span>
            </Button>

        </div>
        <div v-else>
                <v-row dense>
                    <v-col>
                        <ActionCombo :disabled="user.isLoading" no-clearable placeholder="administratie" :rules="[v => !!v || 'Selecteer een administratie']"  v-model="id_company" :items="user.administrations"></ActionCombo>
                    </v-col>
                </v-row>

                <v-row class="mb-2" dense>
                    <Button :loading="user.isLoading" :disabled="user.isLoading" type="submit" block main primary large @click="onSubmit">Selecteer administratie</Button>
                </v-row>
        </div>
    </v-form>

</template>

<script setup>
    import ActionCombo from '@controls/combo/ActionCombo'
    import Icon from '@controls/icons/Icon'

    import user from '@app/user'
    import Button from '@controls/buttons/ActionButton'
    import eventbus from "@app/eventbus"
    import { ref } from 'vue'
    const form = ref(null)

//
// Usage: 
//
//   import ForgotPasswordForm from '@shared/ui/auth/ForgotPasswordForm'
//

    var id_company = ref(null);
    async function onSubmit() {
        await onSelect(id_company.value);
    }

    async function onSelect(id) {

        var f = form?.value;
        if (!f||!f.validate||!f.validate()) {
            return false;
        }
        var result = await user.selectAdministration(id);
        if (!result.success) {
            eventbus.snackbar.error({text: result.message || "Het laden van de applicatie is mislukt"});
            return;
        }
        
        // And after loading the data, go to the 'home' page.
        eventbus.route.go('/');
    }

</script>
